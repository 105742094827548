@import "~@beewise/theme/build/reusables";
@import "~@beewise/theme/build/variables";

.signin {
  @include auth-form;

  &-form {
    width: 318px;

    .text-field-root + .text-field-root {
      margin-top: 50px;
    }
  }

  .input-password {
    margin-top: 50px;
  }

  .forgot-password {
    @extend .white-box;
    margin-top: $spacing;
    padding: $spacing;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 360px;
    line-height: 20px;

    &-icon {
      path {
        stroke: $good;
      }
    }

    &-bold {
      font-weight: 700;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & > div:not(.icon) {
        display: flex;
        font-size: 14px;
        color: $black;
        align-items: flex-start;
        width: 100%;
      }
    }

    &-resend {
      display: inline;
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      font-size: 14px;
      color: $light-blue;
    }

    &-margin {
      margin-top: $spacing;
    }

    &-tip {
      flex-direction: column;


      & > div {
        color: $dark-grey;
      }
    }
  }
}
