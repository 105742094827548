@import "~@beewise/theme";

.badge {
  padding: 3px 8px;
  background-color: $grey-8;
  border-radius: 5px;
  vertical-align: center;
  margin-right: 2px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .icon {
    display: inline;
    width: 12px;
    height: 12px;

    svg {
      width: 14px;
      height: 14px;
      cursor: pointer;
      position: absolute;
    }
  }
}

.badge-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.ranches {
  .grid-wrapper {
    min-height: calc(100vh - #{$app-header-height});
  }
  .ag-theme-material .ag-popup {
    .ag-select-list {
      overflow-y: scroll;
      max-height: 600px;
    }
  }
}

