@import "~@beewise/theme/build/variables";
.status-renderer {
  position: relative;
  display: flex;
  align-items: center;

  .bad,
  .good {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .bad {
    background-color: $bad;
  }
  .good {
    background-color: $good;
  }
}
