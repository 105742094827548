.grid {
  height: calc(100% - 76px);

  &-search {
    width: 50vw;
    height: 36px;
    margin: $spacing auto;
  }

  .plus-button {
    display: block;
  }

  .minus-button {
    display: none;
  }

  .ag-header-cell[col-id="addMode"] {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ag-react-container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-add-mode {
    .plus-button {
      display: none;
    }

    .minus-button {
      display: block;
    }
  }

  .add-mode-cell {
    display: flex;
    justify-content: center;
  }

  .grid-link-wrapper {
    cursor: pointer;
    text-decoration: underline;
    color: $link;
  }

  &.ag-theme-material .ag-cell.ag-cell-not-inline-editing.ag-cell-focus {
    border-color: transparent;
  }

  &.ag-theme-material .ag-cell.ag-cell-not-inline-editing.ag-cell-focus.non-editable-cell {
    border: 1px solid $bad;
  }

  &.ag-theme-material .ag-cell.ag-cell-not-inline-editing.ag-cell-focus.editable-cell {
    border: 1px solid $blue;
  }

  &.ag-theme-material .ag-row[row-id="NOT_EDITABLE_FIELD"] .ag-cell.ag-cell-not-inline-editing.ag-cell-focus.non-editable-cell {
    border-color: $blue;
  }
}
