.weather-data {
  .row {
    padding: 24px;
    align-items: center;

    .react-datepicker-wrapper {
      width: auto;
    }
  }

  .btn {
    &-weather {
      min-width: 150px;
    }
  }
}

