@import "~@beewise/theme/build/variables";
@import "~@beewise/theme/build/reusables";

.app-header {
  background-color: $black;
  height: $app-header-height;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;

  .logo-container {
    padding: 10px 0 10px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .nav-list {
    margin-left: $spacing;
    font-size: 12px;

    .btn {
      display: inline-block;
      padding: 0;
      color: $dark-grey;
      background-color: transparent;
      border: none;
    }

    a {
      color: $dark-grey;
      text-decoration: none;

      &.active {
        font-weight: 700;
        color: $white;
      }
    }

    &-button {
      font-size: 14px;
    }

    &-disabled {
      pointer-events: none;
    }

    a + a {
      margin-left: 12px;
    }

    a + button {
      margin-left: 12px;
    }
  }

  .main-menu {
    margin-left: auto;
    display: flex;
    align-items: center;

    .user-white {
      path {
        stroke: $white;
      }
    }

    button.icon {
      padding: 0;
      background-color: transparent;
    }
  }

  .side-panel-toggler {
    display: none;
  }
}

.profile {
  background-color: $grey;
  padding: $spacing;
  display: flex;
  flex-direction: column;

  &-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-form {
    @extend .white-box;
    padding: $double-spacing $spacing $spacing;
    margin-top: $spacing;
    display: flex;
    flex-direction: column;
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $spacing;

    .reset-password {
      color: $dark-grey;
    }

    .logout {
      color: $bad;
    }
  }

  /* TODO: remove once content will be added to this section */
  .notifications-form {
    padding: 128px $spacing;
  }

  .text-field-root + .text-field-root {
    margin-top: 50px;
  }

  &-switch {
    rect:first-of-type {
      fill: $dark-grey;
    }
    rect:last-of-type {
      stroke: $dark-grey;
    }
  }

  .profile-item + .profile-item {
    margin-top: $spacing;
  }
}

.settings-modal {
  overflow-y: scroll;
}

.stop-modal {
  &.modal-wrapper .modal-content {
    width: 30vw;
  }
  &-text {
    padding: $spacing;
  }

  .modal-submit-container {
    padding: 0 $spacing $spacing;
    justify-content: flex-end;

    .btn + .btn {
      margin-left: $spacing;
    }
  }
}

@media (max-width: $tablet-max-width) {
  .app-header {
    .side-panel-toggler {
      display: block;
      cursor: pointer;
    }
  }
}
