.grid-select {
  width: 100%;
  margin: auto;
  height: 100%;
  &#view {
    min-height: 100% !important;
  }

  .grid-wrapper {
    height: 55vh;
    .grid {
      height: 100%;
    }
  }
}
