@import "~@beewise/theme/build/variables";

.software-update {
  display: flex;
  flex-direction: column;

  .software-update-select-bundle {
    width: auto;
    min-width: 500px;
  }
  &-content {
    display: flex;
    gap: $spacing * 5;
  }

  &-section:first-of-type {
    width: 30%;
  }

  &-section {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  &-input {
    width: 70%;
  }

  .btn-primary {
    width: 338px;
    margin-right: $spacing;
  }
  .help-grey {
    margin-top: 12px;
  }
}
