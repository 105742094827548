.public-bhome-codes {
  width: 100%;
  .grid-wrapper {
    min-height: calc(100vh - #{$app-header-height});
  }

  .copy-renderer {
    .icon-copy {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
