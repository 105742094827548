.bhomes {
  .grid-wrapper {
    min-height: calc(100vh - #{$app-header-height});
  }
  .icon-download {
    transform: rotate(90deg);
    padding: 2px;
    cursor: pointer;
    display: block;
    width: 50%;
  }
}
