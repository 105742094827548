@import "~@beewise/theme";

@import '~react-datepicker/dist/react-datepicker.css';

@import "~@beewise/icon/build/lib/Icon";
@import "~@beewise/button/build/lib/Button";
@import "~@beewise/modal/build/lib/Modal";
@import "~@beewise/toast/build/lib/Toast";

@import "components/reusables/Grid/Grid";
@import "components/reusables/JsonEditor/editor.css";

@import "components/views/SignIn/SignIn";
@import "components/views/Companies/Companies";
@import "components/views/Assemblies/Assemblies";
@import "components/views/Bhomes/Bhomes";
@import "components/views/BhomeModels/BhomeModels";
@import "components/views/BhomeAssemblies/BhomeAssemblies";
@import "components/views/CompanyType/CompanyType";
@import "components/views/CompaniesTypes/CompaniesTypes";
@import "components/views/CompanyBhomes/CompanyBhomes";
@import "components/views/CompanyUsers/CompanyUsers";
@import "components/views/LocationTypes/LocationTypes";
@import "components/views/Locations/Locations";
@import "components/views/UserTypes/UserTypes";
@import "components/views/UserRoles/UserRoles";
@import "components/views/Users/Users";
@import "components/views/UserBhomes/UserBhomes";
@import "components/views/UserPermissions/UserPermissions";
@import "components/views/Dashboard/Dashboard";
@import "components/views/Settings/Settings";
@import "components/views/Incubators/Incubators";
@import "components/views/PublicBhomeCodes/PublicBhomeCodes";

#app {
  font-size: 14px;
  min-height: 100vh;

  h2 {
    font-size: 24px;
  }

  #view {
    display: grid;
    grid-template-areas:
            'beehome-header aside'
            'main aside';
    grid-template-columns: 1fr auto;
    position: relative;
    min-height: calc(100vh - #{$app-header-height});
    margin-left: auto;
    background-color: $grey;

    &.menu-hidden {
      width: calc(100vw - 56px);
    }

    .beehome-header {
      grid-area: beehome-header;
    }

    .main {
      grid-area: main;
      background-color: $grey;
      min-height: calc(100vh - #{$app-header-height} - 72px);
    }
  }
}

.app {
  button {
    font-size: 13px;
    font-weight: 500;
  }

  .side-menu {
    top: $app-header-height;
  }

  .no-bhome-view {
    padding: $spacing;
    font-size: 16px;
    font-weight: 500;
  }

  .view {
    width: 100%;
    padding: $spacing;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 24px;
  }

  .ui.bottom.attached.segment.active.tab {
    box-sizing: border-box;
  }
  .row {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
  }

  .error {
    color: $bad;
  }
}

.preloader-wrapper {
  position: relative;

  .preloader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, .5);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      path {
        stroke: $brand;
      }
    }
  }
}

textarea {
  resize: none;
  padding: 8px;
}

.select-field__indicators:before {
  right: 10px;
}
