@import "~@beewise/theme/build/variables";

.dashboard {
  padding: $spacing;
  background-color: $white;
  min-height: calc(100vh - #{$app-header-height});
  box-sizing: border-box;

  .permissions-json-editor {
    margin: $spacing 0;
  }

  &-csv-report-download {
    margin-left: $spacing;
  }

  &-input {
    width: 200px;
    padding: 5px 0;
  }

  &-login {
    margin-bottom: 10px;
  }

  &-login-item + &-login-item {
    margin-left: $spacing;
  }

  &-login-wrapper {
    display: flex;
  }

  .hidden {
    display: none;
  }

  .dashboard-login-wrapper:not(.hidden) + .dashboard-form {
    margin-top: $double-spacing;
  }

  &-form {
    width: 25vw;

    &-input {
      margin-top: 30px;
    }

    .select-field-root {
      margin-top: $spacing;
    }

    .creatable-select {
      margin-top: $spacing;

      > div {
        border-color: rgba(34, 36, 38, .15);
        min-height: 43px;
      }
    }

    .floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
      top: -15px;
    }
  }

  &-actions {
    margin-top: $spacing;
    display: flex;
    flex-direction: row;
    align-items: center;

    button + button {
      margin-left: $spacing;
    }
  }

  &-result {
    a, .btn-primary {
      margin-top: $spacing;
    }
    a {
      display: block;
    }
  }

  &-bhome-form {
    margin-top: $double-spacing;

    .btn-primary {
      margin-top: $spacing;
    }
  }

  .bhome-creation {
    &-form {
      margin-top: $double-spacing;
    }

    .btn-primary {
      margin-top: $spacing;
    }

    .text-field-root + .text-field-root {
      margin-top: $double-spacing;
    }

    .clear-bhome {
      margin-top: $spacing;
    }

    &-label {
      font-size: 16px;
      margin: $spacing 0 10px;
    }
    .beewise-checkbox {
      margin-left: 16px;
    }
  }

  .bhome-reassign {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
  }

  .software-bundle {
    display: flex;
    justify-content: space-around;
    gap: 60px;
    align-items: stretch;
    div, textarea {
      flex: 1;
    }
    &-input {
      margin-bottom: $spacing;
    }

    .select-field-root + .text-field-root {
      margin-top: $spacing;
    }
    &-info {
      color: $gray;
      font-size: 14px;

      .bold {
        font-weight: 700;
      }
    }
  }

  .bulk-update-settings {
    display: flex;
    gap: 10px;
    flex-direction: column;
    .text-field-root {
      margin: $spacing 0;
    }

    .error {
      margin-top: $spacing;
    }
    .btn-primary {
      max-width: 300px;
    }

    &-item {
      max-width: 800px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .select-field-root {
        min-width: 200px;
      }
    }
  }

  .cloud-config-alert {
    color: red;
    font-size: 16px;
    font-weight: 700;
  }

  .config {
    width: 100%;
    padding: $spacing;
    box-sizing: border-box;

    .cloud-config-alert {
      margin: 10px 0;
    }

    .select-field-root {
      max-width: 220px;
    }

    &-no-settings {
      margin-top: $spacing;
    }

    &-updater {
      font-size: 14px;
      color: $gray-04;
      margin-top: 2px;
    }

    &-content {
      @extend .white-box;
      margin-top: $spacing;

      .text-field-root {
        width: 25%;
        margin-bottom: $spacing;
      }

      .btn {
        margin-top: $spacing;
      }

      .jsoneditor {
        height: 50vh;
      }
    }

    &-content + &-content {
      margin-top: $spacing;
    }

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: $spacing;

      .beewise-checkbox {
        margin-right: $spacing;
      }

      .btn {
        margin: 0 $spacing 0 0;
      }
    }

    &-global {
      &-title {
        margin-bottom: $spacing;
      }

      .btn {
        margin-top: $spacing;
      }

      .jsoneditor {
        height: 60vh;
      }

      .jsoneditor-remove {
        display: none;
      }

      .jsoneditor-field {
        pointer-events: none;
      }

      .jsoneditor-tree {
        background-color: $white;
      }
    }

    .error {
      margin-bottom: $spacing;
      font-size: 18px;
    }

    &-hash-checkbox.ui.checkbox {
      display: block;
      margin-top: 10px;
    }
  }

  .revert-config {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .config-content {
      width: 100%;
    }

    .select-field-root {
      margin-bottom: $spacing;
    }

    .select-bhome {
      min-width: 160px;
    }

    .select-history {
      min-width: 220px;
    }
  }

  &-company-delete {
    margin-top: $spacing;

    .btn-primary {
      margin-top: $spacing;
    }
  }

  &-bhome-delete {
    margin-top: $spacing;
    display: flex;
    gap: $spacing;
  }

  .error {
    height: 16px;
    font-size: 12px;
  }

  .config-mode-switch {
    margin-right: 10px;
  }
}

.rsw-ce, .text-edited {
  ul {
    list-style: circle inside;
  }
  ol {
    list-style: decimal inside;
  }
  b {
    font-weight: 600;
  }
  i {
    font-style: italic;
  }

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
}
